export const clientSidePagination=(pageState,setPageState)=>{
    
    const {
        rows,
        total,
        page,
        pageSize
    } = pageState;

    setPageState({...pageState,isLoading:true});
    
    const totalRows = rows.length;
    const totalPages = Math.ceil(totalRows / pageSize);
  
    // Clamp page to the last available page if it exceeds totalPages - 1
    const currentPage = Math.min(page, totalPages - 1);
  
    const startIndex = currentPage * pageSize;
    const endIndex = startIndex + pageSize;
    const paginatedRows = rows.slice(startIndex, endIndex);

    setPageState({...pageState,updated:true,filteredData:paginatedRows,isLoading:false});
    
}
