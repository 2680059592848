import styled from "@emotion/styled";
import { Accordion, Card, TablePagination } from "@mui/material";
import MuiAccordion from '@mui/material/Accordion';
import { styled as MaterialStyle} from '@mui/material/styles';

export const DynamicCardContainer=styled(Accordion)(({theme,...props})=>`

    position:relative;
    background:#FAFAFA;
    gap:16px;
    border-radius:12px !important;
    opacity:0px;
    width:100%;
    margin:0.5rem 0 !important;
    overflow:visible;
    
    &::before{
      display: none;
    }

    & .MuiAccordionSummary-root{
        min-height:auto !important;
    }
    & .header{

        & .MuiAccordionSummary-content{
            width:100%;
            justify-content:space-between;
            margin-bottom:.5rem;
        }
    }

    & .MuiAccordionActions-root{
        padding-top:0;
        padding-bottom:0;
    }

    & .close{

        display:none;
        cursor:pointer;
        transition: .2s ease-in-out all;
        position:absolute;
        top:-18px;
        right:-15px;
        zIndex:1;
        background: rgba(254, 235, 238, 1);
        border:4px solid #FFFFFF;
    }

    & .truncate{
    
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        "-webkit-line-clamp": 2;
        "-webkit-box-orient": vertical;
    }

    @media (max-width:900px){

        & .close{
            display:flex;
            
        }

    }
    
    @media (min-width:901px){
    
        :hover{

            & .close{
                display:flex;
                
            }
            
        }
            
    }
    
`)

export const DynamicCardAccordion = MaterialStyle((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    
    background:'transparent',
    '&::before': {
      display: 'none',
    },
    '& .MuiAccordionSummary-root':{
        padding:0,
        minHeight:'auto',
        fontWeight:'bold !important'
    },
    '& .MuiAccordionSummary-content':{
        margin:0
    },

    '& .MuiAccordionDetails-root':{
        paddingRight:0
    }

  }));
