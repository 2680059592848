import { useSelector } from "react-redux";
import { features } from "../../utils/access";

const useHasAccess = (module) => {
  const feature = features[module];
  const policies = useSelector((state) => state.app.policies);

  // Club Based Feature filter
  if (feature && policies) {
    const featureSupports = policies?.club?.featureSupports;
    const isAllowed = featureSupports?.find(
      (feat) => feat?.name == feature?.alias
    );

    if (!!isAllowed?.status) {
      return true;
    } else {
      return false;
    }
  }
};
export default useHasAccess;
