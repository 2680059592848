import {features, getHasCapability} from '../../utils/access';
import useUser from './useUser';
import useHasAccess from './useHasAccess';

function useHasCapability(capability, module) {

  const feature = features[module];
  const isAccessible = useHasAccess(module);
  const user = useUser();
  const userPermissions = user?.userObj?.adminRole?.permissions;
  
  if(feature && !isAccessible)
    return false;

  if (userPermissions){
    return getHasCapability(userPermissions, capability, module);
  }else {
    return false;
  }
}

export default useHasCapability;
