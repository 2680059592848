export function downloadCSV(data, fileName) {
  // Convert array of objects to CSV string
  const csvContent = generateCSVContent(data);

  // Create a Blob containing the CSV data with explicit UTF-8 encoding
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

  // Create a link element
  const link = document.createElement('a');

  // Set the link's properties
  link.href = URL.createObjectURL(blob);
  link.download = fileName + '.csv';

  // Append the link to the document
  document.body.appendChild(link);

  // Trigger a click on the link to open the file save popup
  link.click();

  // Remove the link from the document
  document.body.removeChild(link);
}

function generateCSVContent(data) {
  // Extract keys from the first object to use as column headers
  const headers = Object.keys(data[0]);

  // Create CSV header row, removing non-printable characters
  const headerRow = headers.map(header => cleanCSVValue(header)).join(',');

  // Create CSV content by mapping each object to a CSV row
  const contentRows = data.map(obj =>
      headers.map(key => cleanCSVValue(obj[key])).join(',')
  );

  // Combine header and content rows
  return headerRow + '\n' + contentRows.join('\n');
}

function cleanCSVValue(value) {
  // Convert the value to a string and remove non-printable characters
  const stringValue = String(value).replace(/[^\x20-\x7E]/g, '');

  // If the value contains a comma or space, wrap it in double quotes and escape existing double quotes
  if (stringValue.includes(',') || stringValue.includes(' ')) {
    return '"' + stringValue.replace(/"/g, '""') + '"';
  }

  return stringValue;
}

