import {Box, Button, Divider, Drawer, IconButton, Stack, Typography, useTheme} from '@mui/material';
import LayoutMainHeaderTopClubSelect from './LayoutMainHeaderTopClubSelect';
import SiteUserProfile from '../../components/_Global/Site/SiteUserProfile';
import {LAYOUT_MAIN__HEADER__TOP__HEIGHT} from '../../constants/ui';
import ContainerFullWidth
  from '../../components/_Global/Containers/ContainerFullWidth';
import LayoutMainHeaderTopLogo from './LayoutMainHeaderTopLogo';
import { useTranslate } from '@tolgee/react';
import { useEffect } from 'react';
import { useState } from 'react';
import IconAnnouncment from '../../assets/icons/IconAnnouncment';
import IconLeft from '../../assets/icons/IconLeft';
import useIsMobile from '../../hooks/ui/useIsMobile';
import { Menu } from '@mui/icons-material';
import LayoutMainHeaderBottom from './LayoutMainHeaderBottom';
import ProfileImageViewer from '../../components/_Global/Profile/ProfileImageViewer';
import { useSelectedClub } from '../../hooks/clubs/useSelectedClub';
import SiteLanguageSwitcher from '../../components/_Global/Site/SiteLanguageSwitcher';
import { useLocation } from 'react-router-dom';
import IconHelp from '../../assets/icons/helpIcon.svg';
function LayoutMainHeaderTop({role, disableDropdown,isMobile}) {

  const theme = useTheme();
  const {t} = useTranslate();
  const url  = useLocation();

  const [isZendeskOpen, setIsZendeskOpen] = useState(false);
  const [viewMenu,setViewMenu] = useState(false);

  const selectedClub = useSelectedClub();

  const openZendeskWidget = () => {
      if (typeof window.openCloseZendeskWidget === 'function') {
        window.openCloseZendeskWidget();
        setIsZendeskOpen(!isZendeskOpen);
      } else {
        console.error('Zendesk open function is not defined');
      }
  };

  const toggleDrawer = (newOpen) => {
    setViewMenu(newOpen);
  };

  const MenuHeader=()=>{

    return(
      <Stack>
        <Stack 
        direction={'row'} 
        spacing={1} 
        justifyContent={'space-between'} 
        alignItems={'center'} 
        sx={{ maxWidth:'75vw',my:'1rem',padding:'0px 15px'}}
        >
          <>
            <ProfileImageViewer
            size={'xs'}
            imgPath={selectedClub?.imageUrl}
            placeholderStyle={'shield-icon'}
            />
            <Typography>
              {selectedClub?.name}
            </Typography>
          </>
          <>
            <SiteLanguageSwitcher onSuccess={()=>toggleDrawer(false)}/>
          </>
        </Stack>
        <Divider/>
        <Box sx={{p:'1rem 12px'}}>
          <SiteUserProfile role={role}/>
        </Box>
      </Stack>
    )

  }

  const renderNavMobileHeaderIcon=role=>{
 
    return(
      <Stack direction={'row'} alignItems={'center'} spacing={1}>
        {
        isMobile?
          <>
            <Menu fontSize='medium' onClick={()=>toggleDrawer(true)}/>

            <Drawer anchor={'right'} open={viewMenu} onClose={()=>toggleDrawer(false)}>
              {
                selectedClub?
                <MenuHeader/>
                :
                <Stack sx={{ maxWidth:'75vw',mt:'1rem',padding:'5px 15px'}}>
                  <LayoutMainHeaderTopLogo/>
                </Stack>
              }
              <Divider/>
              <LayoutMainHeaderBottom role={role}/>
            </Drawer>
          
          </>:
          <SiteUserProfile role={role}/>
        }
        
      </Stack>
    )
  }

  useEffect(()=>{

    setViewMenu(false);
  },[url])
  
  return (

      <Box
          sx={{
            borderBottom: `1px solid ${theme.palette.divider}`,
            backgroundColor: theme.palette.neutral.primary,
          }}
      >
        <ContainerFullWidth>
          <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                height: LAYOUT_MAIN__HEADER__TOP__HEIGHT,
              }}
          >
            
            {
                role === 'super_admin' &&
                <LayoutMainHeaderTopLogo/>
            }
            {
                role === 'club_manager' &&
                <LayoutMainHeaderTopClubSelect disableDropdown={disableDropdown}/>
            }
            
            <Stack
                sx={{marginLeft: 'auto'}}
                direction={'row'}
                alignItems={'center'}
                columnGap={'15px'}
            >
           
            {/* <Button sx={{minWidth:'auto',p:0}} color={isZendeskOpen ? 'primary' :'inherit'} onClick={openZendeskWidget}>
              <Typography sx={{fontSize: '14px',
                        fontWeight: 500,padding:'.4rem', ml: 0.2}}>
                    {t('zendesk.button')}
                        </Typography>
              </Button> */}
              <Box mr={5}>
                <Button  color='inherit' id='intercomBtn'>
                  <Typography sx={{fontSize: '14px',
                        fontWeight: 500,padding:'.4rem', ml: 0.5}}>
                    {t('global.intercom.buttonTitle')}
                  </Typography>
                </Button>
              </Box>  
              <Box mr={!isMobile && 5}>
                {/*<LayoutMainHeaderTopMetrics/>*/}
                {/* {
                  isMobile? */}
                  <Button sx={{minWidth:'auto',p:0}} color='inherit' className='beamerTrigger'>
                    <Typography sx={{fontSize: '14px',
                          fontWeight: 500,padding:'.4rem'}}>
                      {t('global.beamer.buttonTitle')}
                    </Typography>
                  </Button>
                  {/* : */}
                  {/* <IconButton className='beamerTrigger'>
                    <IconAnnouncment fontSize='large' fill={'rgba(0, 0, 0, 0.56)'} />
                  </IconButton> */}
                {/* } */}
              </Box>  
              {renderNavMobileHeaderIcon(role)}
            </Stack>
          </Box>
        </ContainerFullWidth>
      </Box>

  );
}

export default LayoutMainHeaderTop;
