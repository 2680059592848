import {useTranslate} from '@tolgee/react';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import useHasCapability from '../../hooks/access/useHasCapability';
import {
  CAPABILITY_READ,
  CAPABILITY_READ_ALL,
} from '../../constants/capabilities';
import {
  MODULE_ADMINS,
  MODULE_BOOKINGS,
  MODULE_CLUBS,
  MODULE_COMPANY,
  MODULE_COURTS,
  MODULE_CUSTOMERS,
  MODULE_EVENTS,
  MODULE_EXTRAS,
  MODULE_FINANCIALS,
  MODULE_HOLIDAYS,
  MODULE_INTEGRATIONS,
  MODULE_OPEN_MATCHES,
  MODULE_PACKAGES,
  MODULE_POLICIES,
  MODULE_PRICING,
  MODULE_REPORTS,
  MODULE_SCHEDULE,
  MODULE_TRAINING,
  MODULE_WALLET,
} from '../../constants/modules';
import DateRangeIcon from '@mui/icons-material/DateRange';
import {DoNotDisturb, Payments} from '@mui/icons-material';
import GroupIcon from '@mui/icons-material/Group';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import SettingsIcon from '@mui/icons-material/Settings';
import SurroundSoundIcon from '@mui/icons-material/SurroundSound';
import SportsBaseballIcon from '@mui/icons-material/SportsBaseball';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import TuneIcon from '@mui/icons-material/Tune';
import WorkIcon from '@mui/icons-material/Work';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import PeopleIcon from '@mui/icons-material/People';
import SpokeIcon from '@mui/icons-material/Spoke';
import {LayoutMainNavbarNav} from './LayoutMainNavbarNav';
import { useSelector } from 'react-redux';
import { callGTMEvent } from '../../utils/gtmEvents';
import { isOwnUser, isTestClub } from '../../utils/ui';
import { useSelectedClub } from '../../hooks/clubs/useSelectedClub';

export function LayoutMainNavbarClubManager() {

  const {t} = useTranslate();

  const selectedClubIntegrationList = useSelector(state => state.app.clubsIntegrations);
  const selectedClub = useSelectedClub();
  const clubId = selectedClub?.id;

  const settingsSubItems = [
    {
      icon: SurroundSoundIcon,
      name: t('settings.clubModule.cardCourt.name'),
      route: `/settings/courts`,
      disabled: false,
      canAccess: useHasCapability(CAPABILITY_READ_ALL, MODULE_COURTS),
      onClick:()=>callGTMEvent('settings','clicksOnCourtsFromDropown')
    },
    {
      icon: SportsBaseballIcon,
      name: t('settings.clubModule.cardExtra.name'),
      route: `/settings/extras`,
      disabled: false,
      canAccess: useHasCapability(CAPABILITY_READ_ALL, MODULE_EXTRAS),
        onClick:()=>callGTMEvent('settings','clicksOnExtrasFromDropdown')
    },
    {
      icon: CreditCardIcon,
      name: t('settings.clubModule.cardPricing.name'),
      route: `/settings/pricing`,
      disabled: false,
      canAccess: useHasCapability(CAPABILITY_READ_ALL, MODULE_PRICING),
        onClick:()=>callGTMEvent('settings','clicksOnPricingFromDropdown')
    },
    {
      icon: DoNotDisturb,
      name: t('settings.clubModule.cardHolidays.name'),
      route: `/settings/holidays`,
      disabled: false,
      canAccess: useHasCapability(CAPABILITY_READ_ALL, MODULE_HOLIDAYS),
        onClick:()=>callGTMEvent('settings','clicksOnHolidayFromDropdown')
    },
    {
      icon: TuneIcon,
      name: t('settings.clubModule.cardPolicies.name'),
      route: `/settings/policies`,
      disabled: false,
      canAccess: useHasCapability(CAPABILITY_READ_ALL, MODULE_POLICIES), //need to change it letter
        onClick:()=>callGTMEvent('settings','clicksOnPoliciesFromDropdown')
    },
    {
      icon: WorkIcon,
      name: t('settings.adminModule.cardCompany.name'),
      route: `/settings/company/detail`,
      disabled: false,
      canAccess: useHasCapability(CAPABILITY_READ, MODULE_COMPANY),
      onClick:()=>callGTMEvent('settings','clicksOnCompanyFromDropdown')
    },

    {
      icon: VerifiedUserIcon,
      name: t('settings.adminModule.cardClub.name'),
      route: `/settings/clubs`,
      disabled: false,
      canAccess: useHasCapability(CAPABILITY_READ_ALL, MODULE_CLUBS),
        onClick:()=>callGTMEvent('settings','clicksOnClubsFromDropdown')
    },
    {
      icon: PeopleIcon,
      name: t('settings.adminModule.cardAdmin.name'),
      route: `/settings/manage-admins`,
      disabled: false,
      canAccess: useHasCapability(CAPABILITY_READ_ALL, MODULE_ADMINS),
        onClick:()=>callGTMEvent('settings','clicksOnAdminsFromDropdown')
    },
    {
      icon: SpokeIcon,
      name: t('settings.adminModule.cardIntegeration.name'),
      route: '/settings/integration',
      disabled: false,
      canAccess: useHasCapability(CAPABILITY_READ_ALL, MODULE_INTEGRATIONS) && selectedClubIntegrationList?.length > 0,
      onClick:()=>callGTMEvent('settings','clicksOnIntegrationsFromDropdown')
    },
  ]

  const navItems =  [
    {
      name: t('sideBar.link.textSchedule'),
      route: `/schedule`,
      icon: CalendarTodayIcon,
      disabled: false,
      canAccess: useHasCapability(CAPABILITY_READ_ALL,MODULE_SCHEDULE),
      onClick:()=>callGTMEvent('headers','clickOnSchedule'),
    },
    {
      name: t('sideBar.link.textBooking'),
      route: `/bookings`,
      icon: DateRangeIcon,
      disabled: false,
      canAccess: useHasCapability(CAPABILITY_READ_ALL, MODULE_BOOKINGS),
      onClick:()=>callGTMEvent('headers','clickOnBookings'),
    },
    {
      name: t('sideBar.link.textOpenMatches'),
      route: `/openMatches`,
      icon: DateRangeIcon,
      disabled: false,
      canAccess: useHasCapability(CAPABILITY_READ_ALL, MODULE_OPEN_MATCHES),
      onClick:()=>callGTMEvent('headers','clickOnBookings'),
    },
    {
      name: t('navItems.link.textEvents'),
      route: `/events`,
      icon: DateRangeIcon,
      disabled: false,
      canAccess: useHasCapability(CAPABILITY_READ_ALL, MODULE_EVENTS),
      onClick:()=>callGTMEvent('headers','clickOnEvents'),
    },
    {
      name: t('sideBar.link.textTraining'),
      route: `/training`,
      icon: Payments,
      disabled: false,
      canAccess: useHasCapability(CAPABILITY_READ_ALL, MODULE_TRAINING),
      onClick:()=>callGTMEvent('headers','clickOnTraining'),
    },
    {
      name: t('sideBar.link.textWallet'),
      route: `/wallets`,
      icon: Payments,
      disabled: false,
      canAccess: useHasCapability(CAPABILITY_READ_ALL, MODULE_WALLET),
      onClick:()=>callGTMEvent('headers','clickOnWallets'),
    },
    {
      name: t('sideBar.link.textFinancials'),
      route: `/finances`,
      icon: Payments,
      disabled: false,
      canAccess: useHasCapability(CAPABILITY_READ_ALL, MODULE_FINANCIALS),
      onClick:()=>callGTMEvent('headers','clickOnFinances'),
    },
    {
      name: t('sideBar.link.textCustomer'),
      route: `/customers`,
      icon: GroupIcon,
      disabled: false,
      canAccess: useHasCapability(CAPABILITY_READ_ALL, MODULE_CUSTOMERS),
      onClick:()=>callGTMEvent('headers','clickOnCustomers'),
    },
    {
      name: t('sideBar.link.textReport'),
      route: `/reports`,
      icon: EqualizerIcon,
      disabled: false,
      canAccess: useHasCapability(CAPABILITY_READ_ALL, MODULE_REPORTS),
      onClick:()=>callGTMEvent('headers','clickOnReports'),
    },
    {
      name: t('sideBar.link.textPackage'),
      route: `/packages`,
      icon: EqualizerIcon,
      disabled: false,
      canAccess: useHasCapability(CAPABILITY_READ_ALL, MODULE_PACKAGES), //letter change with packages module
      onClick:()=>callGTMEvent('headers','clickOnPackages'),
    },
    // {
    //   name: t('sideBar.link.textCommunication'),
    //   route: '/communications',
    //   icon: MailIcon,
    //   disabled: true,
    //   canAccess: true,
    // },
    {
      name: t('sideBar.link.textSetting'),
      route: `/settings`,
      icon: SettingsIcon,
      disabled: false,
      canAccess: settingsSubItems?.filter(x=>!!x?.canAccess)?.length>0,
      onClick:()=>callGTMEvent('headers','clickOnSettings'),
      subMenuItems: settingsSubItems
    }]

  return <LayoutMainNavbarNav navItems={navItems}/>;
}