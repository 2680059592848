import Intercom from "@intercom/messenger-js-sdk";
import useUser from '../hooks/access/useUser';
import HmacSHA256 from 'crypto-js/hmac-sha256';
import Hex from 'crypto-js/enc-hex';
import { useEffect } from "react";
import { useSelectedClub } from "../hooks/clubs/useSelectedClub";

const IntercomComponent=props=>{

    
  const selectedClub = useSelectedClub();
  const clubId = selectedClub?.id;
  const user = useUser();
  const userObj = user?.userObj;

  const secretKey = process.env.REACT_APP_INTERCOM_SECRET_KEY ?? '';
  const userIdentifier = userObj?.id?.toString() ?? '';
  const hash = HmacSHA256(userIdentifier, secretKey).toString(Hex);  

  Intercom({
    app_id: process.env.REACT_APP_INTERCOM_API_ID,
    user_id: userObj?.id, // IMPORTANT: Replace "user.id" with the variable you use to capture the user's ID
    user_hash:hash,
    name: user? `${userObj?.firstName} ${userObj?.lastName}`:'', // IMPORTANT: Replace "user.name" with the variable you use to capture the user's name
    email: userObj?.email ?? '', // IMPORTANT: Replace "user.email" with the variable you use to capture the user's email
    created_at: user?.createdAt ?? '', // IMPORTANT: Replace "user.createdAt" with the variable you use to capture the user's sign-up date in a Unix timestamp (in seconds) e.g. 1704067200
  });

  window.intercomSettings = {
    alignment: 'right',        // Left or right position on the page
    horizontal_padding: 50,   // Horizontal padding
    vertical_padding: 40,      // Vertical padding
    custom_launcher_selector:'#intercomBtn',
    hide_default_launcher:true
  };

  useEffect(()=>{

    if(selectedClub && window?.Intercom){
        window.Intercom('update',{
          company:{
            id:selectedClub?.id,
            name:selectedClub?.name,
          },
        })
    }
  },[selectedClub,window?.Intercom])
  useEffect(()=>{
    if(window?.Intercom){
      window.Intercom('update',{
        "User Type":'Admin'
      })
    }
  },[window?.Intercom])

  return(
    <>
    </>
  )

}
export default IntercomComponent;